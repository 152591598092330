/* You can add global styles to this file, and also import other style files */

/*================================================
Default CSS
=================================================*/
:root {
    --fontFamily: 'Josefin Sans', sans-serif;
    --fontFamily2: 'Cookie', cursive;
    --mainColor: #009305;
    --whiteColor: #ffffff;
    --blackColor: #000000;
}
body {
    font-family: var(--fontFamily);
    padding: 0;
    margin: 0;
}
a {
    color: var(--blackColor);
    text-decoration: none;
    transition: .4s;
    display: inline-block;
    outline: 0;

    &:hover, &:focus {
        text-decoration: none;
        color: var(--mainColor);
    }
}
img {
    max-width: 100%;
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    }
}
.ml-auto {
    margin-left: auto;
}
p {
    font-size: 16px;
    color: #999999;
    line-height: 1.6;
}

/* btn */
.btn {
    border-width: 2px;
    line-height: unset;
	padding: 12px 35px 8px;
	border-radius: 0;
	text-transform: uppercase;
	letter-spacing: 2px;
    font: {
        weight: 600;
        size: 15px;
    }
}
.btn-primary {
	color: var(--whiteColor);
	background-color: var(--mainColor);
    border-color: var(--mainColor);
    position: relative;
    z-index: 1;
    transition: .6s;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 50%;
        background: var(--mainColor);
        z-index: -1;
        transition: .4s;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50%;
        background: var(--mainColor);
        z-index: -1;
        transition: .4s;
    }
    &.disabled, &:disabled {
        color: var(--whiteColor);
        background-color: var(--mainColor);
        border-color: var(--mainColor);
        opacity: 1;
    }
    &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show > &.dropdown-toggle {
        color: var(--mainColor);
        background-color: transparent;
        border-color: var(--mainColor);
    }
    &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus, .show > &.dropdown-toggle:focus {
        box-shadow: unset;
    }
    &.focus, &:focus {
        box-shadow: unset;
    }
    &:hover, &:focus {
        color: var(--mainColor);
        background-color: transparent;
        border-color: var(--mainColor);

        &::before {
            height: 0;
        }
        &::after {
            height: 0;
        }
    }
}
/* Owl btn */
.owl-theme {
    .owl-dots {
        margin-top: 50px !important;

        .owl-dot {
            span {
                width: 15px;
                height: 15px;
                margin: 0 5px;
                background: #dddddd;
                transition: .4s;
            }
            &.active, &:hover, &:focus {
                span {
                    background: var(--mainColor);
                }
            }
        }
    }
}
/* Section Title */
.section-title {
    text-align: center;
    margin: {
        top: -13px;
        bottom: 55px;
    }
    span {
        font-family: var(--fontFamily2);
        display: block;
        color: var(--mainColor);
        margin-bottom: 3px;
        font: {
            size: 28px;
        }
    }
    h2 {
        margin: 0;
        font: {
            size: 40px;
            weight: 600;
        }
    }
}
/* form control */
.form-control {
    height: 45px;
    font-size: 16px;
    color: var(--blackColor);
    border: 1px solid #eeeeee;
    border-radius: 5px;
    padding: {
        top: 8px;
        left: 10px;
    };
    &::placeholder {
        color: #777777;
    }
    &:focus {
        box-shadow: unset;
        border-color: var(--mainColor);
    }
}
:focus {
    outline: 0 !important;
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader-area {
    position: fixed;
    left: 0;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 999999;
    background: var(--mainColor);

    .sk-circle {
        width: 60px;
        height: 60px;
        position: relative;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 48%;
        transform: translateY(-48%);

        .sk-child {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;

            &::before {
                content: '';
                display: block;
                margin: 0 auto;
                width: 15%;
                height: 15%;
                background-color: var(--whiteColor);
                border-radius: 100%;
                animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
            }
        }
        .sk-circle2 {
            transform: rotate(30deg);

            &::before {
                animation-delay: -1.1s;
            }
        }
        .sk-circle3 {
            transform: rotate(60deg);

            &::before {
                animation-delay: -1s;
            }
        }
        .sk-circle4 {
            transform: rotate(90deg);

            &::before {
                animation-delay: -0.9s;
            }
        }
        .sk-circle5 {
            transform: rotate(120deg);

            &::before {
                animation-delay: -0.8s;
            }
        }
        .sk-circle6 {
            transform: rotate(150deg);

            &::before {
                animation-delay: -0.7s;
            }
        }
        .sk-circle7 {
            transform: rotate(180deg);

            &::before {
                animation-delay: -0.6s;
            }
        }
        .sk-circle8 {
            transform: rotate(210deg);

            &::before {
                animation-delay: -0.5s;
            }
        }
        .sk-circle9 {
            transform: rotate(240deg);

            &::before {
                animation-delay: -0.4s;
            }
        }
        .sk-circle10 {
            transform: rotate(270deg);

            &::before {
                animation-delay: -0.3s;
            }
        }
        .sk-circle11 {
            transform: rotate(300deg);

            &::before {
                animation-delay: -0.2s;
            }
        }
        .sk-circle12 {
            transform: rotate(330deg);

            &::before {
                animation-delay: -0.1s;
            }
        }
    }
}
@-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/*================================================
Header Area CSS
=================================================*/
.top-area {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	z-index: 999;
    background: transparent;

    .top-bar {
        background: transparent;
        border-bottom: 1px solid rgba(255, 255, 255, .4);
        padding: {
            top: 15px;
            bottom: 12px;
        }
        .call-to-action {
            p {
                margin: 0 13px 0 0;
                display: inline-block;
                color: var(--whiteColor);
                font-size: 16px;

                i {
                    margin-right: 2px;
                    font-size: 17px;
                }
                a {
                    color: var(--whiteColor);

                    &:hover, &:focus {
                        color: var(--mainColor);
                    }
                }
            }
        }
        .top-social {
            float: right;
            padding: 0;
            margin: 0 0 0 10px;
            list-style-type: none;

            li {
                display: inline-block;
                margin-left: 8px;

                a {
                    color: var(--whiteColor);
                    font-size: 16px;

                    &:hover, &:focus {
                        color: var(--mainColor);
                    }
                }
                &:first-child {
                    margin-left: 0;
                }
            }
        }
        .opening-hours {
            float: right;

            p {
                font-size: 16px;
                color: var(--whiteColor);
                margin-bottom: 0;

                i {
                    margin-right: 3px;
                    font-size: 17px;
                }
            }
        }
        &.template-color-bg {
            background: var(--mainColor);
            border-bottom: none;

            .call-to-action {
                p {
                    a {
                        color: var(--whiteColor);
                    }
                }
            }
            .top-social {
                li {
                    a {
                        color: var(--whiteColor);
                    }
                }
            }
        }
    }
}
.bg-light {
	background-color: transparent !important;
}
.transparent-navbar {
    padding: {
        top: 15px;
        bottom: 15px;
    };
    &.navbar-expand-lg {
        .navbar {
            padding: {
                left: 0;
                right: 0;
            };
        }
        .navbar-brand {
            &.black-logo {
                display: none;
            }
        }
        .navbar-nav {
            .nav-item {
                padding: {
                    right: 15px;
                    left: 15px;
                }
                .nav-link {
                    color: var(--whiteColor);
                    text-transform: uppercase;
                    padding: {
                        right: 0;
                        left: 0;
                        top: 0;
                        bottom: 0;
                    }
                    font: {
                        size: 14px;
                        weight: 600;
                    }
                    &.active, &.show, &:hover, &:focus {
                        color: var(--mainColor);
                    }
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
    &.is-sticky {
        .navbar-brand {
            display: none;

            &.black-logo {
                display: block;
                padding: {
                    top: 0;
                    bottom: 0;
                }
            }
        }
        .navbar-nav {
            .nav-item {
                margin-top: 3px;

                .nav-link {
                    color: var(--blackColor);

                    &.active, &.show, &:hover, &:focus {
                        color: var(--mainColor);
                    }
                }
            }
        }
    }
}
.white-bg-navbar {
    &.navbar-expand-lg {
        background: var(--whiteColor) !important;
        padding: {
            top: 12px;
            bottom: 12px;
        };
        .navbar-brand {
            display: none;

            &.black-logo {
                display: block;
            }
        }
        .navbar-nav {
            .nav-item {
                margin-top: 7px;
                padding: {
                    right: 15px;
                    left: 15px;
                }
                .nav-link {
                    color: var(--blackColor);
                    text-transform: uppercase;
                    padding: {
                        right: 0;
                        left: 0;
                        top: 0;
                        bottom: 0;
                    }
                    font: {
                        size: 14px;
                        weight: 600;
                    }
                    &.active, &.show, &:hover, &:focus {
                        color: var(--mainColor);
                    }
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}
.middle-logo-navbar {
    &.navbar-expand-lg {
        .navbar-brand {
            display: none;
        }
        .navbar-nav {
            margin: {
                left: auto;
                right: auto;
            }
            .nav-item {
                padding: {
                    right: 25px;
                    left: 25px;
                    top: 25px;
                }
                &.logo {
                    padding: {
                        right: 45px;
                        left: 45px;
                        top: 0;
                    };
                    .black-logo {
                        display: none;
                    }
                }
                &:first-child {
                    padding-left: 0;
                }
            }
        }
        &.navbar-light {
            &.is-sticky {
                padding: {
                    top: 5px;
                    bottom: 15px;
                };
            }
        }
    }
    &.white-bg-navbar {
        &.navbar-expand-lg {
            .navbar-brand {
                &.black-logo {
                    display: none;
                }
            }
            .navbar-nav {
                .nav-item {
                    &.logo {
                        padding-top: 0;

                        img {
                            display: none;
                        }
                        .black-logo {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    &.is-sticky {
        .navbar-brand {
            display: none;

            &.black-logo {
                display: none;
            }
        }
        .navbar-nav {
            .nav-item {
                &.logo {
                    img {
                        display: none;
                    }
                    .black-logo {
                        display: block;
                    }
                }
            }
        }
    }
}
.navbar.navbar-light.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: var(--whiteColor) !important;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding: {
        top: 10px;
        bottom: 10px;
    };
}
.transparent-navbar {
    &.navbar-expand-lg {
        .navbar-toggler {
            border-radius: 0;
            box-shadow: unset;
            color: var(--whiteColor);
            border-color: var(--whiteColor);

            .navbar-toggler-icon {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
            }
        }
    }
}
.navbar {
    &.navbar-light {
        &.is-sticky {
            .navbar-toggler {
                box-shadow: unset;
                border-radius: 1px;
                color: var(--blackColor);
                border-color: var(--blackColor);

                .navbar-toggler-icon {
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
                }
            }
        }
    }
    &.white-bg-navbar {
        &.navbar-expand-lg {
            .navbar-toggler {
                box-shadow: unset;
                border-radius: 1px;
                color: var(--blackColor);
                border-color: var(--blackColor);

                .navbar-toggler-icon {
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
                }
            }
        }
    }
}

// Mobile and iPad Navbar
@media only screen and (max-width: 991px) {

    .transparent-navbar {
        padding: {
            top: 12px;
            bottom: 12px;
        };
        &.navbar-expand-lg {
            .navbar-collapse {
                background: var(--whiteColor);
                margin-top: 10px;

                .navbar-nav {
                    padding: 15px 0;

                    .nav-item {
                        padding: {
                            right: 15px;
                            left: 15px;
                            bottom: 10px;
                            top: 10px;
                        }
                        .nav-link {
                            color: var(--blackColor);

                            &:hover, &:focus {
                                color: var(--mainColor);
                            }
                            &.active, &.show, &:hover, &:focus {
                                color: var(--mainColor);
                            }
                        }
                    }
                }
            }
        }
    }
    .navbar {
        &.navbar-light {
            &.is-sticky {
                padding: 10px 15px;

                .navbar-collapse {
                    border: 1px solid #eeeeee;
                }
            }
        }
        &.middle-logo-navbar {
            &.navbar-expand-lg {
                .navbar-brand {
                    display: block;

                    &.black-logo {
                        display: none;
                    }
                }
                .navbar-nav {
                    .nav-item {
                        &.logo {
                            display: none;
                        }
                    }
                }
            }
            &.is-sticky {
                .navbar-brand {
                    display: none;

                    &.black-logo {
                        display: block;
                    }
                }
            }
        }
        &.white-bg-navbar {
            &.navbar-expand-lg {
                .navbar-nav {
                    .nav-item {
                        padding: {
                            right: 15px;
                            left: 15px;
                            bottom: 10px;
                            top: 10px;
                        }
                    }
                }
            }
        }
        &.middle-logo-navbar {
            &.white-bg-navbar {
                &.navbar-expand-lg {
                    .navbar-brand {
                        display: none;

                        &.black-logo {
                            display: block;
                        }
                    }
                }
            }
        }
    }

}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
    height: 100vh;
    z-index: 1;
    position: relative;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: {
            position: center center;
            image: url(assets/img/dots.png);
            repeat: repeat;
        }
    }
    .bottom-bg {
        opacity: 1;
    }
    #canvas {
        opacity: 1;
    }
    &.ripple-effect {
        &::before {
            z-index: 1;
        }
        .main-banner-content {
            position: relative;
            z-index: 2;
        }
    }
}
.item-bg-one {
    background-image: url(assets/img/main-banner-1.jpg);
}
.item-bg-two {
    background-image: url(assets/img/main-banner-2.jpg);
}
.item-bg-three {
    background-image: url(assets/img/main-banner-3.jpg);
}
.main-banner-content {
    margin-top: 50px;
    text-align: center;

    h1 {
        font-family: var(--fontFamily2);
        color: var(--whiteColor);
        margin-bottom: 25px;
        font: {
            size: 130px;
            weight: 700;
        }
    }
    h3 {
        color: var(--whiteColor);
        letter-spacing: 2px;
        margin-bottom: 30px;
        font: {
            size: 25px;
            weight: 500;
        }
        .typewrite {
            color: var(--whiteColor);
        }
    }
}
.down_arrow {
    .scroll_down {
        left: 0;
        right: 0;
        height: 50px;
        width: 30px;
        z-index: 5;
        bottom: 30px;
        background-color: transparent;
        border: 2px solid var(--whiteColor);
        border-radius: 20px;
        cursor: pointer;
        animation: movebounce 3s linear infinite;
        outline: none;
        position: absolute;
        margin: {
            left: auto;
            right: auto;
        }
        &::before {
            position: absolute;
            top: 10px;
            left: 50%;
            content: '';
            width: 6px;
            height: 6px;
            margin-left: -3px;
            background-color: var(--whiteColor);
            border-radius: 100%;
            animation: scroll_down_btn-animation 2s infinite;
        }
    }
}
@keyframes scroll_down_btn-animation {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes scroll_down_btn-animation {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
#particles-js {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
    height: 100%;
    z-index: -1;
}
#particles-js-2 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
    height: 100%;
    z-index: -1;
}
.video-banner {
    overflow: hidden;
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        background-color: #18181b;
        opacity: .50;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        height: 100%;
        width: 100%;
    }
    .video-background {
        position: absolute;
        left: 0;
        top: 0;
        min-height: 100%;
        min-width: 100%;
        z-index: -2;
    }
}
@keyframes swell {
    0%, 100% {
        transform: translate3d(0,-25px,0);
    }
    50% {
        transform: translate3d(0,5px,0);
    }
}
.home-slides {
    .owl-nav {
        margin-top: 0 !important;

        [class*="owl-"] {
            position: absolute;
            font-size: 25px !important;
            margin: 0;
            padding: 0 !important;
            background: rgba(255, 255, 255, .4) !important;
            border-radius: 50%;
            top: 50%;
            left: 40px;
            width: 50px;
            color: var(--whiteColor) !important;
            height: 50px;
            line-height: 56px !important;
            transition: .4s;

            &:hover {
                background: var(--mainColor) !important;
            }
        }
        .owl-next {
            left: auto;
            right: 40px;
        }
    }
}

/*================================================
Story Area CSS
=================================================*/
.story-area {
    position: relative;
    z-index: 1;
}
.story-image {
    .col-lg-6 {
        img {
            margin-top: 30px;
            border-radius: 3px;

            &:nth-child(1) {
                margin-top: 0;
            }
        }
    }
}
.story-content {
    max-width: 570px;

    .section-title {
        text-align: left;
        margin-bottom: 15px;
    }
    .story-quote {
        .quote-content {
            p {
                margin-bottom: 20px;
            }
        }
        .quote-info {
            position: relative;
            margin-top: 30px;
            padding: {
                left: 85px;
                top: 10px;
            }
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 65px;
                height: 65px;
                border-radius: 50%;
            }
            h3 {
                text-transform: uppercase;
                margin-bottom: 3px;
                color: var(--blackColor);
                font: {
                    size: 18px;
                    weight: 700;
                }
            }
            span {
                display: block;
                color: #999999;
            }
        }
    }
}
#particles-js-circle-bubble-1 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.line-bg {
	position: absolute;
	right: 0;
	bottom: 0;
	height: auto;
	width: auto;
	z-index: 2;
}

/*================================================
Offer Area CSS
=================================================*/
.offer-area {
    position: relative;
    z-index: 1;
    background: {
        image: url(assets/img/fp/P1000153.jpg);
        position: center center;
        size: cover;
        // attachment: fixed;
        repeat: no-repeat;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--blackColor);
        opacity: .6;
        z-index: -1;
    }
    .section-title {
        h2 {
            color: var(--whiteColor);
        }
    }
}
.single-offer {
    a {
        display: block;

        img {
            border-radius: 5px;
        }
    }
    .offer-content {
        background-color: var(--whiteColor);
        padding: 30px;
        position: relative;
        border-radius: 5px;
        margin: {
            left: 30px;
            right: 30px;
            top: -30px;
        }
        a {
            display: inline-block;
        }
        h3 {
            margin-bottom: 10px;
            font: {
                size: 22px;
                weight: 700;
            }
            span {
                display: block;
                font-size: 15px;
                text-transform: capitalize;
                margin-bottom: 10px;
                color: var(--mainColor);
            }
        }
        p {
            margin-bottom: 20px;
        }
    }
}

/*================================================
Stunning Things Area CSS
=================================================*/
.stunning-things {
    padding-bottom: 70px;
    overflow: hidden;
    position: relative;
    background: {
        image: url(assets/img/bg.png);
        attachment: fixed;
        repeat: repeat;
    }
}
.single-box {
	background-color: var(--whiteColor);
	text-align: center;
	padding: 25px;
	margin-bottom: 30px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    transition: .4s;

    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 3px;
        border-radius: 0 0 5px 5px;
        left: 0;
        background: var(--mainColor);
        bottom: 0;
        transition: .4s;
    }

    .icon {
        font-size: 45px;
        color: #999999;
        margin-top: -9px;
        transition: .4s;
    }
    h3 {
        font: {
            size: 22px;
            weight: 700;
        }
        margin: {
            top: 10px;
            bottom: 15px;
        }
    }
    p {
        margin-bottom: 0;
    }
    &:hover {
        box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
        transform: translateY(-5px);

        &::before {
            width: 100%;
        }
        .icon {
            color: var(--mainColor);
        }
    }
}

/*================================================
Video Area CSS
=================================================*/
.video-area {
    position: relative;
    z-index: 1;
    background: {
        image: url(assets/img/video-bg.jpg);
        position: center center;
        size: cover;
        // attachment: fixed;
        repeat: no-repeat;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--blackColor);
        opacity: .6;
        z-index: -1;
    }
    .video-content {
        text-align: center;

        h2 {
            color: var(--whiteColor);
            text-transform: capitalize;
            font: {
                size: 50px;
                weight: 700;
            }
            margin: {
                top: -5px;
                bottom: 0;
            }
        }
        .video-btn {
            display: inline-block;
            width: 80px;
            height: 80px;
            font-size: 40px;
            background-color: var(--whiteColor);
            border-radius: 50%;
            color: var(--mainColor);
            margin-top: 30px;
            position: relative;
            z-index: 1;

            &::after, &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
                bottom: 0;
                left: 0;
                transition: .5s;
                border-radius: 50%;
                border: 1px solid var(--whiteColor);
            }
            &::before {
                animation: ripple 2s linear infinite;
            }
            &::after {
                animation: ripple 2s linear 1s infinite;
            }
            i {
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            &:hover {
                color: var(--whiteColor);
                background-color: var(--mainColor);

                &::before, &::after {
                    border-color: var(--mainColor);
                }
            }
        }
    }
}
@keyframes ripple {
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.75);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

/*================================================
Restaurant Menu Area CSS
=================================================*/
.menu-area {
	position: relative;
    z-index: 1;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: repeating-linear-gradient(135deg, #212529, #212529 5px, rgba(0,0,0,0) 5px, rgba(0,0,0,0) 10px);
        z-index: -1;
        opacity: 0.009;
    }
}
.tab {
    overflow: hidden;

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .tabs {
        text-align: center;
        margin-bottom: 40px;

        li {
            display: inline-block;
            margin: 0 30px;

            a {
                color: var(--blackColor);
                text-decoration: none;
                display: inline-block;
                font: {
                    size: 18px;
                    weight: 500;
                }
                i {
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    background: #edf5ff;
                    text-align: center;
                    font-size: 30px;
                    border-radius: 50%;
                    margin-bottom: 15px;
                    transition: .4s;
                }
                &:hover, &:focus {
                    color: var(--mainColor);

                    i {
                        color: var(--whiteColor);
                        background-color: var(--mainColor);
                    }
                }
            }
            &.current {
                a {
                    color: var(--mainColor);

                    i {
                        color: var(--whiteColor);
                        background-color: var(--mainColor);
                    }
                }
            }
        }
    }
    .tabs_item {
        display: none;
        position: relative;
        z-index: 1;
        background: {
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
        padding: {
            top: 30px;
            left: 30px;
            right: 30px;
            bottom: 0;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--whiteColor);
            opacity: .8;
            z-index: -1;
        }
        &:first-child {
            display: block;
        }
        .single-menu {
            position: relative;
            margin-bottom: 30px;
            padding-left: 110px;

            .food-menu-img {
                position: absolute;
                left: 0;
                top: 0;
                width: 90px;
                height: 90px;

                img {
                    border-radius: 50%;
                }
            }
            .food-menu-content {
                h3 {
                    color: var(--mainColor);
                    font: {
                        size: 22px;
                        weight: 700;
                    }
                    margin: 0 0 9px;
                    overflow: hidden;

                    .menu-price {
                        float: right;
                        font: {
                            weight: 400;
                            size: 20px;
                        }
                    }
                }
                ul {
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    margin: {
                        right: -15px;
                        left: -15px;
                    }
                    li {
                        color: #000000;
                        -ms-flex: 0 0 33.333333%;
                        flex: 0 0 33.333333%;
                        max-width: 33.333333%;
                        margin-top: 6px;
                        font-weight: 600;
                        padding: {
                            left: 15px;
                            right: 15px;
                        }
                    }
                }
            }
        }
    }
    .tab-item-bg-one {
        background-image: url(assets/img/offer-img1.jpg);
    }
    .tab-item-bg-two {
        background-image: url(assets/img/offer-img2.jpg);
    }
    .tab-item-bg-three {
        background-image: url(assets/img/offer-img3.jpg);
    }
    .tab-item-bg-four {
        background-image: url(assets/img/offer-img4.jpg);
    }
    .tab-item-bg-five {
        background-image: url(assets/img/offer-img1.jpg);
    }
    .tab-item-bg-six {
        background-image: url(assets/img/offer-img2.jpg);
    }
}
.menu-area-two {
    padding-bottom: 60px;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: repeating-linear-gradient(135deg, #212529, #212529 5px, rgba(0,0,0,0) 5px, rgba(0,0,0,0) 10px);
        z-index: -1;
        opacity: 0.009;
    }
    .shape2 {
        top: 10%;
    }
}
.single-restaurant-menu {
	margin-bottom: 40px;
    position: relative;
    padding-left: 110px;

    .menu-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 85px;
        height: 85px;

        img {
            border-radius: 50%;
        }
    }
    .menu-content {
        h3 {
            margin: 0 0 9px;
            overflow: hidden;
            font: {
                size: 22px;
                weight: 700;
            }
            .price {
                float: right;
                font: {
                    size: 20px;
                    weight: 400;
                }
                color: var(--mainColor);
            }
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            margin-bottom: 0;
            list-style-type: none;
            margin: {
                right: -15px;
                left: -15px;
            }
            li {
                color: #777777;
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
                margin-top: 6px;
                font-weight: 600;
                padding: {
                    left: 15px;
                    right: 15px;
                }
            }
        }
    }
}
.laureel-food-menu {
    margin-bottom: 40px;

    h3 {
        margin-bottom: 25px;
        border-bottom: 3px solid #eeeeee;
        padding-bottom: 5px;
        display: inline-block;
        font: {
            size: 24px;
            weight: 700;
        }
    }
    .single-box-menu {
        position: relative;
        margin-bottom: 20px;
        background: var(--whiteColor);
        box-shadow: 2px 2px 10px rgba(72, 73, 121, 0.15);
        padding: {
            left: 135px;
            bottom: 30px;
            right: 30px;
            top: 30px;
        }
        .food-image {
            position: absolute;
            left: 30px;
            top: 30px;
            width: 85px;
            height: 85px;

            img {
                border-radius: 50%;
            }
        }
        .menu-content {
            h4 {
                margin: 0 0 9px;
                overflow: hidden;
                font: {
                    size: 22px;
                    weight: 700;
                }
                .price {
                    float: right;
                    color: var(--mainColor);
                    font: {
                        size: 20px;
                        weight: 400;
                    }
                }
            }
            p {
                margin-bottom: 0;
            }
        }
    }
}
.menu-item {
    margin-bottom: 40px;

    .menu-image {
        position: relative;

        .price {
            position: absolute;
            right: 0;
            bottom: 0;
            background: var(--mainColor);
            color: var(--whiteColor);
            padding: 6px 10px 4px;
            border-radius: 5px 0 0 0;
        }
    }
    .menu-content {
        margin-top: 20px;

        h3 {
            margin-bottom: 9px;
            font: {
                size: 22px;
                weight: 700;
            }
        }
        p {
            margin-bottom: 0;
        }
    }
}

/*================================================
Reservation Area CSS
=================================================*/
.reservation-area {
    z-index: 1;
    text-align: center;
    position: relative;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
        // attachment: fixed;
        image: url(assets/img/reservation-bg.jpg);
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--blackColor);
        opacity: .5;
        z-index: -1;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: -1;
        height: 100%;
        background: {
            position: center center;
            image: url(assets/img/dots.png);
            repeat: repeat;
        }
    }
    h2 {
        color: var(--whiteColor);
        text-transform: capitalize;
        font: {
            size: 50px;
            weight: 700;
        }
        margin: {
            top: -5px;
            bottom: 25px;
        }
    }
}

/*================================================
Reservation Modal CSS
=================================================*/
#reservationModal {
    .modal-dialog {
        max-width: 650px;

        .modal-content {
            border: none;
            border-radius: 5px;

            .modal-header {
                padding: 25px 25px 20px;
                display: block;
                background: var(--mainColor);
                position: relative;

                .modal-title {
                    margin-bottom: 0;
                    line-height: 1;
                    color: var(--whiteColor);
                    font-size: 22px;
                }
                .close {
                    padding: 0;
                    margin: 0;
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    opacity: 1;
                    color: var(--whiteColor);
                }
            }
            .modal-body {
                padding: 25px;

                .modalForm {
                    overflow: hidden;

                    .form-control {
                        padding: 4px 10px 0;
                    }
                    .btn {
                        text-align: center;
                        font-size: 14px;
                        letter-spacing: 1px;
                        border-radius: 1px;
                        padding: 12px 30px 8px;
                        margin-top: 4px;
                        float: right;
                    }
                }
            }
        }
    }
}

/*================================================
Chefs Area CSS
=================================================*/
.chefs-area {
    position: relative;
    z-index: 1;
}
.single-chefs {
    text-align: center;

    .chefs-image {
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background: var(--mainColor);
            z-index: 1;
            opacity: 0;
            transition: .4s;
            transform: translate(-50%, -50%) scale(0);
        }
        img {
            transform: scale(1) rotate(0);
            transition: .4s;
        }
        .chefs-social {
            position: absolute;
            top: 60%;
            left: 0;
            right: 0;
            z-index: 3;
            padding: 0;
            margin: 0;
            list-style-type: none;
            opacity: 0;
            visibility: hidden;
            transition: .4s;

            li {
                display: inline-block;
                margin: {
                    left: 3px;
                    right: 3px;
                };
                a {
                    width: 35px;
                    height: 35px;
                    line-height: 40px;
                    background: var(--whiteColor);
                    border-radius: 50%;
                    font-size: 15px;
                }
            }
        }
    }
    .chefs-content {
        margin-top: 25px;

        h3 {
            font: {
                size: 22px;
                weight: 700;
            }
            margin: {
                bottom: 6px;
                top: -4px;
            }
        }
        span {
            color: #999999;
        }
    }
    &:hover {
        .chefs-image {
            &::before {
                opacity: .6;
                transform: translate(-50%, -50%) scale(4);
                transition-duration: .6s;
            }
            img {
                transform: scale(1.2) rotate(5deg);
            }
            .chefs-social {
                opacity: 1;
                visibility: visible;
                top: 50%;
            }
        }
    }
}
.chefs-slides {
    &.owl-theme {
        .owl-dots {
            margin-top: 40px !important;
        }
    }
}

/*================================================
CTA Area CSS
=================================================*/
.cta-area {
    z-index: 1;
    position: relative;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
        // attachment: fixed;
        image: url(assets/img/find-us-bg.jpg);
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--blackColor);
        opacity: .6;
        z-index: -1;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: {
            position: center center;
            image: url(assets/img/dots.png);
            repeat: repeat;
        }
    }
    span {
        color: var(--mainColor);
        display: block;
        font: {
            size: 30px;
            family: var(--fontFamily2);
        }
        margin: {
            bottom: 15px;
            top: -11px;
        }
    }
    h2 {
        margin-bottom: -13px;
        color: var(--whiteColor);
        font: {
            size: 50px;
            weight: 700;
        }
    }
}

/*================================================
Gallery Area CSS
=================================================*/
.gallery-area {
    position: relative;
    z-index: 1;
}
.single-image {
	text-align: center;
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--blackColor);
        opacity: 0;
        visibility: hidden;
        transition: .4s;
    }
    .image-content {
        position: absolute;
        bottom: -32px;
        left: 0;
        width: 100%;
        height: auto;
        background: var(--mainColor);
        z-index: 2;
        padding: 15px 0;
        opacity: 0;
        visibility: hidden;
        transition: .4s;

        h3 {
            margin-bottom: 0;
            font-size: 22px;

            a {
                color: var(--whiteColor);
            }
        }
    }
    .popup-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        font-size: 45px;
        opacity: 0;
        visibility: hidden;
        transition: .4s;
        color: var(--whiteColor);
        margin: {
            top: -80px;
            left: -30px;
        }
        &:hover {
            color: var(--mainColor);
        }
    }
    &:hover {
        &::before {
            opacity: .7;
            visibility: visible;
        }
        .image-content {
            bottom: 0;
            opacity: 1;
            visibility: visible;
        }
        .popup-btn {
            margin-top: -50px;
            opacity: 1;
            visibility: visible;
        }
    }
}

/*================================================
Features Area CSS
=================================================*/
.features-area {
    position: relative;

    .col-lg-4 {
        &:first-child {
            .single-features {
                text-align: right;
                padding: {
                    left: 0;
                    right: 85px;
                }
                .icon {
                    left: auto;
                    right: 0;
                }
            }
        }
    }
}
.single-features {
    position: relative;
    padding-left: 85px;
    margin-bottom: 30px;

    .icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        border: 2px solid #eeeeee;
        border-radius: 50%;
        font-size: 25px;
        transition: .4s;
    }
    .features-content {
        h3 {
            font: {
                size: 22px;
                weight: 700;
            }
            margin-bottom: 8px;
        }
        p {
            margin-bottom: 0;
        }
    }
    &:hover {
        .icon {
            color: var(--whiteColor);
            background: var(--mainColor);
            border-color: var(--mainColor);
            transform: translateY(-5px);
        }
    }
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-area {
    position: relative;
    z-index: 1;
    background: {
        image: url(assets/img/fp/bg-2.jpg);
        position: center center;
        size: cover;
        // attachment: fixed;
        repeat: no-repeat;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--blackColor);
        opacity: .5;
        z-index: -1;
    }
    .section-title {
        h2 {
            color: var(--whiteColor);
        }
    }
}
.single-feedback {
    text-align: center;
    position: relative;

    p {
        color: var(--whiteColor);
        max-width: 700px;
        font-size: 19px;
        margin: {
            left: auto;
            right: auto;
            bottom: 0;
        };
    }
    .client-info {
        margin-top: 30px;

        h3 {
            color: var(--whiteColor);
            text-transform: uppercase;
            font: {
                size: 20px;
                weight: 700;
            }
        }
        span {
            display: block;
            color: #eeeeee;
        }
    }
}
.feedback-slides {
    &.owl-theme {
        .owl-dots {
            margin-top: 30px !important;
        }
    }
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
    position: relative;
    z-index: 1;
    background: {
        image: url(assets/img/bg-section.jpg);
        attachment: fixed;
    };
}
.single-blog-post {
    background: var(--whiteColor);
    margin-bottom: 30px;

    .post-image {
        display: block;
    }
    .blog-post-content {
        padding: 20px;

        ul {
            padding: 0;
            list-style-type: none;
            margin: 0;

            li {
                display: inline-block;
                margin-right: 10px;
                font-size: 15px;
                color: #999999;
                position: relative;
                padding-left: 21px;

                &:last-child {
                    margin-right: 0;
                }
                i {
                    color: var(--mainColor);
                    position: absolute;
                    left: 0;
                    top: 0;

                    &.icofont-wall-clock {
                        top: 2px;
                    }
                }
                a {
                    color: #999999;
                    display: inline-block;

                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
        h3 {
            line-height: 30px;
            margin: {
                top: 13px;
                bottom: 13px;
            }
            font: {
                size: 22px;
                weight: 700;
            }
        }
        p {
            margin-bottom: 20px;
        }
        .read-more-btn {
            display: inline-block;

            i {
                position: relative;
                top: 2px;
                margin-left: -1px;
            }
        }
    }
}
.blog-slides {
    &.owl-theme {
        .owl-dots {
            margin-top: 30px !important;
        }
    }
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-area {
    position: relative;
    background: {
        image: url(assets/img/bg-section.jpg);
        attachment: fixed;
    };
    .blog-details {
        box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
        background: var(--whiteColor);

        .article-img {
            position: relative;

            .date {
                position: absolute;
                bottom: 15px;
                width: 100px;
                right: 15px;
                height: 100px;
                text-align: center;
                background: var(--mainColor);
                color: var(--whiteColor);
                border-radius: 5px;
                padding-top: 19px;
                line-height: 32px;
                font: {
                    size: 25px;
                    style: italic;
                };
            }
        }
        .article-content {
            padding: 30px;

            ul {
                li {
                    display: inline-block;
                }
                &.category {
                    margin-bottom: 30px;

                    li {
                        margin-right: 5px;

                        a {
                            background: var(--mainColor);
                            color: var(--whiteColor);
                            padding: 5px 15px 3px;
                            display: inline-block;
                            border-radius: 3px;
                            font-weight: 400;
                        }
                    }
                }
            }
            h3 {
                margin-bottom: -5px;
                font: {
                    size: 24px;
                    weight: 700;
                }
            }
            p {
                margin-bottom: 0;
                margin-top: 20px;
            }
            .blockquote {
                background: #f9f9f9;
                padding: 30px;
                border-left: 4px solid var(--mainColor);
                margin: {
                    bottom: 20px;
                    top: 20px;
                }
                p {
                    margin-top: 0;
                    font-size: 18px;
                }
            }
            .share-post {
                text-align: center;
                margin-top: 30px;

                ul {
                    li {
                        margin: {
                            left: 3px;
                            right: 3px;
                        };
                        a {
                            width: 38px;
                            font-size: 15px;
                            height: 38px;
                            line-height: 41px;
                            border: 1px solid #eeeeee;
                            border-radius: 50%;
                            color: #555555;

                            &:hover, &:focus {
                                color: var(--whiteColor);
                                border-color: var(--mainColor);
                                background-color: var(--mainColor);
                            }
                        }
                    }
                }
            }
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;
            }
        }
    }
    .post-controls-buttons {
        overflow: hidden;
        background: var(--whiteColor);;
        padding: 30px;
        box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
        margin: {
            top: 25px;
            bottom: 25px;
        }
        .controls-left {
            float: left;
        }
        .controls-right {
            float: right;
        }
        div {
            a {
                border: 1px solid #eeeeee;
                padding: 8px 20px 6px;
                border-radius: 5px;

                &:hover, &:focus {
                    color: var(--whiteColor);
                    background: var(--mainColor);
                    border-color: var(--mainColor);
                }
            }
        }
    }
    .post-comments {
        padding: 30px;
        background: var(--whiteColor);
        box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);

        h3 {
            margin-bottom: 0;
            font: {
                size: 24px;
                weight: 700;
            }
        }
        .single-comment {
            position: relative;
            padding-left: 95px;
            margin-top: 30px;

            .comment-img {
                position: absolute;
                left: 0;
                top: 0;
                width: 75px;
                height: 75px;

                img {
                    border-radius: 50%;
                }
            }
            .comment-content {
                h4 {
                    margin-bottom: 10px;
                    font: {
                        size: 19px;
                        weight: 600;
                    }
                }
                p {
                    margin-bottom: 15px;
                }
            }
            &.left-m {
                margin-left: 85px;
            }
        }
    }
    .leave-a-reply {
        padding: 30px;
        background: var(--whiteColor);
        box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
        margin-top: 25px;

        h3 {
            margin-bottom: 25px;
            font: {
                size: 24px;
                weight: 700;
            }
        }
    }
}

/*================================================
Instagram Area CSS
=================================================*/
.instagram-area {
    position: relative;
}
.instagram-title {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 22;
    transform: translate(-50%, -50%);

    h3 {
        text-align: center;
        font-size: 20px;
        margin: 0;

        a {
            display: inline-block;
            padding: 20px 20px 15px;
            background: var(--whiteColor);
        }
    }
}
.instagram-item {
    a {
        display: block;

        img {
            transition: .5s;

            &:hover {
                opacity: 0.5;
            }
        }
    }
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
    position: relative;
    z-index: 1;
}
.partner-item {
    text-align: center;

    a {
        display: block;
        background-color: #f9f9f9;
        border-right: 1px solid #eeeeee;
        padding: 20px;

        img {
            width: auto !important;
            display: inline-block !important;
            opacity: 0.5;
            transition: .4s;
        }
        &:hover {
            img {
                opacity: 1;
            }
        }
    }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-area {
    position: relative;
    z-index: 1;
    background: {
        image: url(assets/img/contact-bg.jpg);
        // attachment: fixed;
        position: top;
        size: cover;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--whiteColor);
        opacity: .95;
        z-index: -1;
    }
}
.contact-box {
    h3 {
        margin-bottom: 20px;
        font: {
            size: 22px;
            weight: 700;
        }
    }
    p {
        position: relative;
        padding-left: 25px;
        color: #999999;
        margin: {
            bottom: 0;
            top: 7px;
        }
        i {
            position: absolute;
            left: 0;
            top: 3px;
            color: var(--mainColor);
        }
        a {
            color: #999999;

            &:hover {
                color: var(--mainColor);
            }
        }
        &.opening-hours {
            overflow: hidden;
            padding: 0;
            text-align: left;

            span {
                float: right;
                margin-right: 25px;
            }
        }
    }
    form {
        .btn {
            display: block;
            font-size: 25px;
            width: 100%;
            padding: 5px 0;
            margin-top: 10px;
            height: 45px;
        }
    }
}
#contactForm {
    background: var(--whiteColor);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    padding: 40px;
    border-radius: 5px;
    margin-top: 50px;

    .btn {
        margin-top: 12px;
    }
}

/*================================================
Page Title CSS
=================================================*/
.page-title-area {
    z-index: 1;
    position: relative;
    text-align: center;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    padding: {
        top: 220px;
        bottom: 100px;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: {
            position: center center;
            image: url(assets/img/dots.png);
            repeat: repeat;
        }
    }
    h3 {
        color: var(--whiteColor);
        text-transform: uppercase;
        margin: 0;
        font: {
            weight: 700;
            size: 35px;
        }
    }
    &.page-title-bg1 {
        background: {
            image: url(assets/img/main-banner-1.jpg);
        }
    }
    &.page-title-bg2 {
        background: {
            image: url(assets/img/main-banner-2.jpg);
        }
    }
    &.page-title-bgferragosto {
        background: {
            image: url(assets/img/offers/summer-bg.jpg);
        }
    }
}

/*================================================
Pagination CSS
=================================================*/
.pagination-navbar {
    margin-top: 35px;

    .pagination {
        padding: 0;
        margin: 0;
        border-radius: 0;

        .page-item {
            .page-link {
                padding: 8px 20px 5px;
                line-height: unset;
                color: var(--blackColor);
                background-color: var(--whiteColor);
                border: 1px solid #eeeeee;
                font: {
                    weight: 600;
                    size: 18px;
                }
                &:hover, &:focus {
                    color: var(--whiteColor);
                    background: var(--mainColor);
                    border-color: var(--mainColor);
                    box-shadow: unset;
                }
            }
            &.active {
                .page-link {
                    color: var(--whiteColor);
                    background: var(--mainColor);
                    border-color: var(--mainColor);
                }
            }
        }
    }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    background: var(--blackColor);
    padding: {
        top: 30px;
        bottom: 30px;
    }
    p {
        margin-bottom: 0;
        color: var(--whiteColor);
    }
    ul {
        text-align: right;
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            position: relative;
            padding-left: 20px;

            a {
                color: var(--whiteColor);

                &:hover {
                    color: var(--mainColor);
                }
            }
            &::before {
                content: '';
                position: absolute;
                left: 8px;
                top: 5px;
                background: #cccccc;
                width: 2px;
                height: 14px;
            }
            &:first-child {
                padding-left: 0;

                &::before {
                    display: none;
                }
            }
        }
    }
}
